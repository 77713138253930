<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold">Registro de usuário</h2>
      </b-col>
    </b-row>

    <b-row class="teacher-profile mt-50">
      <b-col v-if="User.role_id != 10" md="3">
        <ProfilePhoto
          :isEditing="!!id"
          :photo="photo"
          :callback="setNewPhoto"
        />
      </b-col>

      <b-col>
        <b-row>
          <b-col>
            <b-form-group class="required" description>
              <label for="label">Nome</label>
              <b-form-input
                v-model="User.name"
                type="text"
                placeholder
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group class="required" description>
              <label for="label">E-mail</label>
              <b-form-input
                v-model="User.email"
                type="text"
                placeholder
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-if="User.role_id != 10">
            <b-form-group description>
              <label for="label">Telefone</label>
              <b-form-input
                v-model="User.Phones[0].number"
                type="text"
                v-mask="['(##) #####-####']"
                placeholder="(__) _____-____"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col v-if="User.role_id != 10">
            <b-form-group class="required" description>
              <label for="label">CPF</label>
              <b-form-input
                v-model="User.cpf"
                :state="verificarCpf"
                type="text"
                v-mask="['###.###.###-##']"
                placeholder="___.___.___-__"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col v-if="User.role_id != 10">
            <b-form-group description>
              <label for="label">RG</label>
              <b-form-input
                v-model="User.rg"
                type="text"
                v-mask="['#.###.###']"
                placeholder="Somente números"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-if="User.role_id != 10">
            <b-form-group class="required" description>
              <label for="label">Data de nascimento</label>
              <b-form-input
                v-model="User.birth_date"
                type="date"
                placeholder
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="required" description>
              <label for="label">Função</label>
              <b-form-select
                v-model="User.role_id"
                :options="roles"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-3 login-fields" v-if="!id">
          <b-col md-3>
            <b-form-group class="required" description>
              <label for="label">Senha</label>
              <b-form-input
                v-model="User.password"
                type="password"
                placeholder
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md-3>
            <b-form-group class="required" description>
              <label for="label">Confirme a senha</label>
              <b-form-input
                v-model="confirmPassword"
                :class="{ isInvalid: !confirmPasswordValidation }"
                type="password"
                placeholder
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row align-v="center" class="mt-5">
      <b-col></b-col>

      <b-col class="text-right">
        <b-button
          class="large blue outline"
          :to="{
            name: 'usuarios',
          }"
          >Voltar</b-button
        >
      </b-col>

      <b-col></b-col>

      <b-col class="text-right">
        <b-button @click="submit" class="large blue">Salvar</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import utils from "@/utils";
import ProfilePhoto from "@/components/ProfilePhoto";
import HttpRda from "../../../http-rda";
import moment from "moment-timezone";

export default {
  name: "novosusuario",
  components: {
    ProfilePhoto,
  },

  data() {
    return {
      photo: null,
      cyclesSelected: [],
      subjectsSelected: [],
      cyclesFilleds: [],
      studentsToTutor: [],
      subjectsToArticulate: [],
      country: null,
      state: null,
      confirmPassword: "",
      User: {
        name: "",
        email: "",
        password: "",
        birth_date: null,
        cpf: "",
        rg: "",
        Phones: [
          {
            number: "",
            is_mobile: false,
          },
        ],
        gender: "",
        role_id: 0,
      },

      roles: [
        {
          value: 0,
          text: "Selecione",
        },
        {
          value: 7,
          text: "Coordenação",
        },
        {
          value: 8,
          text: "Secretaria",
        },
        {
          value: 9,
          text: "Portaria",
        },
        {
          value: 10,
          text: "QrCode",
        },
        {
          value: 13,
          text: "Gestor de Faltas e Presenças",
        },
      ],

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Usuários",
          href: "/secretaria/usuarios",
        },
        {
          text: "Novo usuário",
          href: "novosusuario",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      countriesList: "shared/countries",
      statesList: "shared/states",
    }),

    confirmPasswordValidation() {
      return this.User.password === this.confirmPassword;
    },

    id() {
      return this.$route.params.id;
    },

    verificarCpf() {
      if (this.User.cpf != undefined) {
        const validarCPF = utils.validateCPF(this.User.cpf);
        return validarCPF ? true : false;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions({
      getCountries: "shared/getCountries",
      getStates: "shared/getStates",
      savePhoto: "community/saveUserPhoto",
    }),

    updatePhoto(id) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", this.User.photo);
      this.savePhoto({ id, file: bodyFormData });
    },

    async postUser() {
      let data = new FormData();

      if (this.photo !== "" || this.photo !== null) {
        data.append("file", this.photo);
      }
      let cpf = this.User.cpf.replace(/[^\d]+/g, "");
      let rg = this.User.rg.replace(/[^\d]+/g, "");

      data.append("name", this.User.name);
      data.append("email", this.User.email);
      data.append("password", this.User.password);
      data.append("cpf", cpf);
      data.append("rg", rg);
      data.append("gender", this.User.gender);
      data.append("birth_date", this.User.birth_date);
      data.append("role_id", this.User.role_id);

      if (this.User.Phones[0].number !== "") {
        let phone = this.User.Phones[0].number.replace(/[^\d]+/g, "");
        console.log(phone);
        data.append("Phones[0][number]", phone);
        data.append("Phones[0][is_mobile]", this.User.Phones[0].is_mobile);
      }

      await HttpRda.post("/collaborator", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          this.$swal.fire({
            title: "Sucesso",
            text: "Usuário cadastrado com sucesso",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            this.$router.push("/administracao/usuarios");
          }, 1000);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.details &&
            error.response.data.details.length > 0
          ) {
            let errorHtml = "";
            error.response.data.details.forEach((err) => {
              errorHtml += `${err}<br>`;
            });

            this.$swal.fire({
              title: "Verifique os erros abaixo!",
              html: errorHtml,
              icon: "error",
              showConfirmButton: true,
              confirmButtonText: "Ok",
            });
          } else {
            this.$swal.fire({
              title: "Algo deu errado!",
              text: "Tente novamente mais tarde!",
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    },

    submit() {
      if (this.User.role_id == 10) {
        this.User.cpf = "00000000000";
        this.User.rg = "0000000";
        this.User.birth_date = moment().format("YYYY-MM-DD");
      }

      if (
        this.User.name == "" ||
        this.User.email == "" ||
        this.User.cpf == "" ||
        this.User.password == "" ||
        this.confirmPassword == "" ||
        (this.User.birth_date === null && this.User.role_id != 10) ||
        this.User.role_id == 0
      ) {
        this.$swal.fire({
          title: "Alerta",
          text: "Preencha todos os campos!",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.postUser();
      }
    },

    setNewPhoto(photo) {
      this.photo = photo;
    },
  },

  created() {
    this.getCountries();
    this.getStates();
  },
};
</script>

<style lang="scss">
.teacher-profile {
  .form-group {
    &.required {
      &:after {
        right: 0;
      }
    }
  }
}

.container-rda .multiselect__tag-icon:after {
  color: white;
}

input.isInvalid {
  border-color: red;
}
</style>
